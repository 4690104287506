import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/pageHero"
import Image from "../components/image"

import room from "../images/room.jpg"
import house from "../images/house.jpg"

import * as data from "../data/home.json"

import "../style/page/index.scss"

const SectionProperty = () => {
  return (
    <section className="property-type">
      <div className="flex-wrapper">
        <div className="type" style={{ backgroundImage: `url(${house})` }}>
          <Link to="/room">
            <div className="content">
              <div className="content-wrapper">
                <p>Unit Type & Rate</p>
                <p>Price from RM2400/month</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="type" style={{ backgroundImage: `url(${room})` }}>
          <Link to="/room">
            <div className="content">
              <div className="content-wrapper">
                <p>Room Type & Rate</p>
                <p>Price from RM700/month</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}

const SectionPurpose = () => {
  return (
    <section className="purpose">
      <div className="header">
        <p>
          We’ve helped thousand of students and family stayed in their perfect
          space.
        </p>
        <Link to="/contact">Let Us Help You Too</Link>
      </div>
      <div className="image">
        <Image />
      </div>
    </section>
  )
}

const SectionContact = () => {
  return (
    <section className="contact">
      <div className="section-wrapper">
        <p>Let's Make a Viewig Appointment Today</p>
        <Link to="/contact">
          Fill up the form, and we'll do the arrangement
        </Link>
      </div>
    </section>
  )
}

const IndexPage = () => (
  <Layout bgColor="#e5c86f">
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Hero>
      <p>
        We are <span>Sunway Rental specialist.</span> Helping to find your new
        Home in Sunway City
      </p>
    </Hero>
    <SectionProperty />
    <SectionPurpose />
    <section className="reason">
      <p className="header">{data.reason.header}</p>
      <p
        className="content"
        dangerouslySetInnerHTML={{ __html: data.reason.main_content }}
      />
      <p className="sub-header">{data.reason.sub_header}</p>
      <p
        className="content"
        dangerouslySetInnerHTML={{ __html: data.reason.secondary_content }}
      />
      <div className="from-right">
        <Link to="/location">More about Location</Link>
      </div>
    </section>
    <SectionContact />
  </Layout>
)

export default IndexPage
